import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import { Toast } from "vant";
import * as moment from "moment";

import navbar from "@/components/navbar";
import tabbar from "@/components/tabbar";
// import loading from "@/components/loading";

Vue.component("navbar", navbar);
Vue.component("tabbar", tabbar);
// Vue.component("loading", loading);

Vue.config.productionTip = false;
Vue.prototype.showLoading = (title = "加载中...") =>
  Toast.loading({ message: title, forbidClick: true, duration: 0 });
Vue.prototype.hideLoading = Toast.clear;
Vue.prototype.$moment = moment;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
