<template>
  <div class="tabbar">
    <van-tabbar
      placeholder
      active-color="#218EE2"
      inactive-color="#818181"
      :border="false"
      :value="current"
    >
      <van-tabbar-item
        v-for="(item, index) in tabList"
        @click="checkTab(index)"
        :key="index"
      >
        <template #icon="props">
          <img class="icon" :src="props.active ? item.selIcon : item.icon" />
        </template>
        <span class="txt">{{ item.name }}</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  name: "HelloWorld",
  props: {
    current: {
      type: String | Number,
      default: 0,
    },
    userType: {
      type: String,
      default: "teacher",
    },
  },
  components: {
    "van-tabbar": Tabbar,
    "van-tabbar-item": TabbarItem,
  },
  data() {
    return {
      tabList: [
        {
          name: "首页",
          icon: require("@/assets/images/tab1.png"),
          selIcon: require("@/assets/images/tab1-on.png"),
          t_url: "/teacher/index",
          f_url: "/",
        },
        {
          name: "通知",
          icon: require("@/assets/images/tab2.png"),
          selIcon: require("@/assets/images/tab2-on.png"),
          t_url: "/teacher/notice",
          f_url: "/notice",
        },
        {
          name: "风采",
          icon: require("@/assets/images/tab3.png"),
          selIcon: require("@/assets/images/tab3-on.png"),
          t_url: "/teacher/article",
          f_url: "/article",
        },
        {
          name: "我的",
          icon: require("@/assets/images/tab4.png"),
          selIcon: require("@/assets/images/tab4-on.png"),
          t_url: "/teacher/userinfo",
          f_url: "/userinfo",
        },
      ],
    };
  },
  methods: {
    checkTab(idx) {
      let info = this.tabList[idx];
      let url =
        this.$store.state.userType == this.$store.state.USER_TEACHER
          ? info.t_url
          : info.f_url;
      if (!url || this.$route.path == url) return;
      this.$router.push(url);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tabbar {
  .txt {
    font-size: 0.22rem;
  }
  .icon {
    width: 0.44rem;
    height: 0.44rem;
  }
}
::v-deep .van-tabbar {
  // box-shadow: 0px 0px .4rem .04rem #b5b4b4;
  box-shadow: 0px -0.16rem 0.4rem 0.02rem rgba(146, 160, 178, 0.1);
}
</style>
