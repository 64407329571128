import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import { getUserType } from "@/common/tool";

const Login = () => import("@/views/Login.vue");
const Forgot = () => import("@/views/Forgot.vue");
const Index = () => import("@/views/Index.vue");
const Notice = () => import("@/views/Notice.vue");
const NoticeDetail = () => import("@/views/NoticeDetail.vue");
const NoticePublish = () => import("@/views/NoticePublish.vue");
const Article = () => import("@/views/Article.vue");
const ArticleDetail = () => import("@/views/ArticleDetail.vue");
const UserInfo = () => import("@/views/Userinfo.vue");
const EditorInfo = () => import("@/views/EditorInfo.vue");
const EditorName = () => import("@/views/EditorName.vue");
const EditorPhone = () => import("@/views/EditorPhone.vue");
const EditorPwd = () => import("@/views/EditorPwd.vue");
const ScoreList = () => import("@/views/ScoreList.vue");
const ScoreDetail = () => import("@/views/ScoreDetail.vue");
const Schedule = () => import("@/views/Schedule.vue");
const AccessRecord = () => import("@/views/AccessRecord.vue");
const Attendance = () => import("@/views/Attendance.vue");
const teacherIndex = () => import("@/views/teacher/Index.vue");
const teacherUserInfo = () => import("@/views/teacher/UserInfo.vue");
const teacherSchedule = () => import("@/views/teacher/Schedule.vue");
const teacherStudentList = () =>
  import("@/views/teacher/student/StudentList.vue");
const teacherStudentInfo = () =>
  import("@/views/teacher/student/StudentInfo.vue");
const teacherStudentAccessRecord = () =>
  import("@/views/teacher/student/AccessRecord.vue");
const teacherStudentAttendance = () =>
  import("@/views/teacher/student/Attendance.vue");
const teacherStudentScoreList = () =>
  import("@/views/teacher/student/ScoreList.vue");
const teacherStudentScoreDetail = () =>
  import("@/views/teacher/student/ScoreDetail.vue");
const teacherAccessRecord = () => import("@/views/teacher/AccessRecord.vue");
const teacherGuardRecord = () => import("@/views/teacher/GuardRecord.vue");
const teacherCampusLog = () => import("@/views/teacher/CampusLog.vue");
const teacherStudentCampusLog = () =>
  import("@/views/teacher/student/CampusLog.vue");
const teacherClockSign = () => import("@/views/teacher/ClockSign.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      keepAlive: false,
    },
    component: Index,
  },
  {
    path: "/forgot",
    name: "login-forgot",
    meta: {
      keepAlive: false,
    },
    component: Forgot,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      keepAlive: false,
    },
    component: Login,
  },
  {
    path: "/notice",
    name: "notice",
    meta: {
      keepAlive: true,
    },
    component: Notice,
  },
  {
    path: "/noticedetail",
    name: "noticedetail",
    meta: {
      keepAlive: false,
    },
    component: NoticeDetail,
  },
  {
    path: "/article",
    name: "article",
    meta: {
      keepAlive: true,
    },
    component: Article,
  },
  {
    path: "/articledetail",
    name: "articledetail",
    meta: {
      keepAlive: false,
    },
    component: ArticleDetail,
  },
  {
    path: "/userinfo",
    name: "userinfo",
    meta: {
      keepAlive: false,
    },
    component: UserInfo,
  },
  {
    path: "/editorinfo",
    name: "editorinfo",
    meta: {
      keepAlive: false,
    },
    component: EditorInfo,
  },
  {
    path: "/editorname",
    name: "editorname",
    meta: {
      keepAlive: false,
    },
    component: EditorName,
  },
  {
    path: "/editorphone",
    name: "editorphone",
    meta: {
      keepAlive: false,
    },
    component: EditorPhone,
  },
  {
    path: "/editorpwd",
    name: "editorpwd",
    meta: {
      keepAlive: false,
    },
    component: EditorPwd,
  },
  {
    path: "/scorelist",
    name: "scorelist",
    meta: {
      keepAlive: true,
    },
    component: ScoreList,
  },
  {
    path: "/scoredetail",
    name: "scoredetail",
    meta: {
      keepAlive: false,
    },
    component: ScoreDetail,
  },
  {
    path: "/schedule",
    name: "schedule",
    meta: {
      keepAlive: false,
    },
    component: Schedule,
  },
  {
    path: "/accessrecord",
    name: "accessrecord",
    meta: {
      keepAlive: false,
    },
    component: AccessRecord,
  },
  {
    path: "/attendance",
    name: "attendance",
    meta: {
      keepAlive: false,
    },
    component: Attendance,
  },
  {
    path: "/teacher/forgot",
    name: "teacher-login-forgot",
    meta: {
      keepAlive: false,
    },
    component: Forgot,
  },
  {
    path: "/teacher/login",
    name: "teacher-login",
    meta: {
      keepAlive: false,
    },
    component: Login,
  },
  {
    path: "/teacher/index",
    name: "teacher-index",
    meta: {
      keepAlive: false,
    },
    component: teacherIndex,
  },
  {
    path: "/teacher/notice",
    name: "teacher-notice",
    meta: {
      keepAlive: true,
    },
    component: Notice,
  },
  {
    path: "/teacher/noticedetail",
    name: "teacher-noticedetail",
    meta: {
      keepAlive: false,
    },
    component: NoticeDetail,
  },
  {
    path: "/teacher/noticepublish",
    name: "teacher-noticepublish",
    meta: {
      keepAlive: false,
    },
    component: NoticePublish,
  },
  {
    path: "/teacher/article",
    name: "teacher-article",
    meta: {
      keepAlive: true,
    },
    component: Article,
  },
  {
    path: "/teacher/articledetail",
    name: "teacher-articledetail",
    meta: {
      keepAlive: false,
    },
    component: ArticleDetail,
  },
  {
    path: "/teacher/userinfo",
    name: "teacher-userinfo",
    meta: {
      keepAlive: false,
    },
    component: teacherUserInfo,
  },
  {
    path: "/teacher/schedule",
    name: "teacher-schedule",
    meta: {
      keepAlive: false,
    },
    component: teacherSchedule,
  },
  {
    path: "/teacher/editorphone",
    name: "teacher-editorphone",
    meta: {
      keepAlive: false,
    },
    component: EditorPhone,
  },
  {
    path: "/teacher/editorpwd",
    name: "teacher-editorpwd",
    meta: {
      keepAlive: false,
    },
    component: EditorPwd,
  },
  {
    path: "/teacher/student/list",
    name: "teacher-student-list",
    meta: {
      keepAlive: true,
    },
    component: teacherStudentList,
  },
  {
    path: "/teacher/student/info",
    name: "teacher-student-info",
    meta: {
      keepAlive: false,
    },
    component: teacherStudentInfo,
  },
  {
    path: "/teacher/student/accessrecord",
    name: "teacher-student-accessrecord",
    meta: {
      keepAlive: false,
    },
    component: teacherStudentAccessRecord,
  },
  {
    path: "/teacher/student/attendance",
    name: "teacher-student-attendance",
    meta: {
      keepAlive: false,
    },
    component: teacherStudentAttendance,
  },
  {
    path: "/teacher/student/scorelist",
    name: "teacher-student-scorelist",
    meta: {
      keepAlive: true,
    },
    component: teacherStudentScoreList,
  },
  {
    path: "/teacher/student/scoredetail",
    name: "teacher-student-scoredetail",
    meta: {
      keepAlive: false,
    },
    component: teacherStudentScoreDetail,
  },
  {
    path: "/teacher/accessrecord",
    name: "teacher-accessrecord",
    meta: {
      keepAlive: false,
    },
    component: teacherAccessRecord,
  },
  {
    path: "/teacher/guardrecord",
    name: "teacher-guardrecord",
    meta: {
      keepAlive: false,
    },
    component: teacherGuardRecord,
  },
  {
    path: "/teacher/campuslog",
    name: "teacher-campuslog",
    meta: {
      keepAlive: false,
    },
    component: teacherCampusLog,
  },
  {
    path: "/teacher/student/campuslog",
    name: "teacher-student-guardrecord",
    meta: {
      keepAlive: false,
    },
    component: teacherStudentCampusLog,
  },
  {
    path: "/teacher/clockSign",
    name: "teacher-clock-sign",
    meta: {
      keepAlive: false,
    },
    component: teacherClockSign,
  }
];

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTE_MODE,
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, form, next) => {
  to.meta.form = form.path;
  window.pageScroll = function () {};
  let hasToken = !!store.state.token.refreshToken,
    isLoginUrl = to.name.indexOf("login") >= 0;
  if (!hasToken && !isLoginUrl) {
    let type = getUserType();
    let url = store.state.urlMap[type].login;
    next(url);
  } else {
    next();
  }
});

export default router;
