import { get, post } from "./request";

function post2get(params) {
  if (!params) return "";
  let res = "";
  let keys = Object.keys(params);
  keys.forEach((item) => {
    res += item + "=" + params[item] + "&";
  });
  return res.substring(0, res.length - 1);
}
//获取字典项
export async function dictData(key) {
  let result = await get("/common/dict/key/" + key);
  return result;
}
//登录
export async function login(params) {
  let query = post2get(params);
  let result = await post("/user/signIn?" + query);
  return result;
}
//注销
export async function logout(params) {
  let result = await post("/user/signOut", params);
  return result;
}
//用户信息
export async function userInfo(params) {
  let result = await get("/user/info", params);
  return result;
}
//短信验证码
export async function sendSms(params) {
  let query = post2get(params);
  let result = await post("/code/sms?" + query);
  return result;
}
//重置密码
export async function resetPassword(params) {
  let query = post2get(params);
  let result = await post("/user/resetPwd?" + query, params);
  return result;
}
//修改密码
export async function updatePassword(params) {
  let query = post2get(params);
  let result = await post("/user/updatePwd?" + query, params);
  return result;
}
//修改名称
export async function updateName(params) {
  let query = post2get(params);
  let result = await post("/user/updateName?" + query, params);
  return result;
}
//课程表
export async function timeTable(params) {
  let result = await get("/class/timetable", params);
  return result;
}
//班级列表
export async function classList(params) {
  let result = await get("/class/select", params);
  return result;
}
//新闻栏目
export async function newsCate(params) {
  let result = await get("/news/category", params);
  return result;
}
//新闻列表
export async function newsList(params) {
  let result = await get("/news/list", params);
  return result;
}
//新闻详情
export async function newsView(params) {
  let result = await get("/news/view", params);
  return result;
}
//学生列表
export async function studentList(params) {
  let result = await get("/student/list", params);
  return result;
}
//学生详情
export async function studentDetail(params) {
  let result = await get("/student/detail", params);
  return result;
}
//小孩列表
export async function childrenList(params) {
  let result = await get("/guardian/children", params);
  return result;
}
//切换当前操作小孩
export async function childSwitch(params) {
  let query = post2get(params);
  let result = await post("/guardian/switch?" + query);
  return result;
}
//门禁日志
export async function studentLog(params) {
  let result = await get("/entrance/student/log", params);
  return result;
}
//门禁日志
export async function childrenLog(params) {
  let result = await get("/entrance/children/log", params);
  return result;
}
//门禁日志
export async function campusLog(params) {
  let result = await get("/entrance/campus/log", params);
  return result;
}
//门禁日志
export async function gateLog(params) {
  let result = await get("/entrance/gate/log", params);
  return result;
}
//考试列表
export async function examList(params) {
  let result = await get("/exam/list", params);
  return result;
}
//考试列表(学生)
export async function studentExamList(params) {
  let result = await get("/exam/listByStudent", params);
  return result;
}
//考试结果
export async function studentExamResult(params) {
  let result = await get("/exam/getStudentExamResult", params);
  return result;
}
//考试结果
export async function classExamResult(params) {
  let result = await get("/exam/getClassExamResult", params);
  return result;
}
//修改手机号码
export async function updateMobile(params) {
  let query = post2get(params);
  let result = await post("/user/updateMobile?" + query);
  return result;
}
//岗位列表
export async function postList(params) {
  let result = await get("/common/select/post", params);
  return result;
}
//部门列表
export async function deptList(params) {
  let result = await get("/common/select/dept", params);
  return result;
}
// 微信认证
export async function getWxAuthUrl(params) {
  let result = await get("/weixin/authorize/url", params);
  return result;
}
//通知列表
export async function notifyList(params) {
  let result = await get("/notify/list", params);
  return result;
}
//通知详情
export async function notifyDetail(params) {
  let result = await get("/notify/view", params);
  return result;
}
//学生到校统计
export async function studentArrivalRecord(params) {
  let result = await get("/student/atSchoolRecord", params);
  return result;
}
//考勤统计
export async function teacherAtSchoolList(params) {
  let result = await get("/stats/teacherAtSchool", params);
  return result;
}
//学生在校统计
export async function studentAtSchoolList(params) {
  let result = await get("/stats/studentAtSchool", params);
  return result;
}
//查询子女门禁记录
export async function childrenGuardianRecord(params) {
  let result = await get("/guardian/atSchoolRecord", params);
  return result;
}
//发布通知
export async function notifySave(params) {
  let result = await post("/notify/save", params);
  return result;
}
//获取教师管理的班级
export async function teacherClassList(params) {
  let result = await get("/teacher/class", params);
  return result;
}
// 教师考勤
export async function teacherClockSignList(params) {
  let result = await get("/clock/sign/teacher/list", params);
  return result;
}
