<template>
  <div class="navbar">
    <van-nav-bar
      fixed
      z-index="10"
      placeholder
      safe-area-inset-top
      :title="title"
      :left-text="isLeft ? leftText : ''"
      :left-arrow="isLeft"
      @click-left="onClickLeft"
    />
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  name: "navbar",
  props: {
    title: {
      type: String,
      default: "",
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    leftText: {
      type: String,
      default: "返回",
    },
  },
  components: {
    "van-nav-bar": NavBar,
  },
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  ::v-deep .van-nav-bar {
    .van-nav-bar__text {
      font-size: 0.28rem;
      font-weight: 400;
    }
    .van-nav-bar__arrow {
      font-size: 0.36rem;
      margin-right: 0.04rem;
    }
    .van-nav-bar__title {
      line-height: 1;
    }
  }
}
</style>
