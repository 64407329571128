export const USER_TEACHER = "01";
export const USER_FAMILY = "03";

export const passTypeMap = {
  1: "进",
  2: "出",
};

export const orderType = {
  asc: "01",
  desc: "02",
};

export function getToken() {
  let store = localStorage.getItem("token");
  return store ? JSON.parse(store) : {};
}

export function setToken(token) {
  return localStorage.setItem("token", JSON.stringify(token));
}

export function removeToken() {
  return localStorage.removeItem("token");
}

export function setUserStorage(info) {
  return localStorage.setItem("userinfo", JSON.stringify(info));
}

export function getUserStorage() {
  let store = localStorage.getItem("userinfo");
  return store ? JSON.parse(store) : {};
}

export function getUserType(name = "") {
  name = name ? "/" + name : location.href;
  let isTeacher = location.href.indexOf("/teacher") >= 0;
  return isTeacher ? USER_TEACHER : USER_FAMILY;
}

export function getGender(type) {
  type = type != undefined ? type.toString() : "";
  switch (type) {
    case "1":
      return "男";
    case "0":
      return "女";
  }
  return "";
}

export function toChinesNum(num) {
  let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    let newArr = [];
    strArr.forEach((item, index) => {
      newArr.unshift(
        item === "0" ? changeNum[item] : changeNum[item] + unit[index]
      );
    });
    let numArr = [];
    newArr.forEach((m, n) => {
      if (m !== "零") numArr.push(n);
    });
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === "零") {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m;
          }
        } else {
          newNum += m;
        }
      });
    } else {
      newNum = newArr[0];
    }

    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = "0" + noWan;
  }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

export function formatDate(date, format = "yyyy-MM-dd hh:mm:ss") {
  date = date ? date : new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  const o = {
    "M+": month,
    "d+": day,
    "h+": hour < 10 ? "0" + hour : hour,
    "m+": minute < 10 ? "0" + minute : minute,
    "s+": second < 10 ? "0" + second : second,
    "q+": Math.floor((month - 1) / 3) + 1,
    S: date.getMilliseconds(),
  };

  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (year + "").substr(4 - RegExp.$1.length)
    );
  }

  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }

  return format;
}

export function throttle(func, delay) {
  let timer = null;

  return function () {
    let context = this;
    let args = arguments;
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(context, args);
        timer = null;
      }, delay);
    }
  };
}

export function isScrollToBottom(padding = 50) {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  const clientHeight =
    document.documentElement.clientHeight || document.body.clientHeight;
  const scrollHeight =
    document.documentElement.scrollHeight || document.body.scrollHeight;

  return scrollTop + clientHeight + padding >= scrollHeight;
}
