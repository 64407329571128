import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";

import {
  USER_TEACHER,
  USER_FAMILY,
  getToken,
  setToken,
  removeToken,
  getUserStorage,
  setUserStorage,
} from "@/common/tool.js";

import {
  login,
  logout,
  userInfo,
  childSwitch,
  deptList,
} from "@/common/api.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    USER_TEACHER: USER_TEACHER,
    USER_FAMILY: USER_FAMILY,
    userType: "",
    token: getToken(),
    userinfo: getUserStorage(),
    urlMap: {
      [USER_TEACHER]: {
        login: "/teacher/login",
        articleDetail: "/teacher/articledetail",
        noticeDetail: "/teacher/noticedetail",
      },
      [USER_FAMILY]: {
        login: "/login",
        articleDetail: "/articledetail",
        noticeDetail: "/noticedetail",
      },
    },
    deptList: [
      {
        deptId: "",
        name: "全部",
      },
    ],
    needUpdate: {
      noticeList: false,
    },
  },
  mutations: {
    SET_USERTYPE: (state, type) => {
      type = state.userinfo.userType ? state.userinfo.userType : type;
      state.userType = type;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    CLEAR_TOKEN: (state) => {
      state.token = {};
      removeToken();
    },
    SET_USERINFO: (state, info) => {
      state.userinfo = info;
      setUserStorage(info);
    },
    SET_CHILDREN: (state, list) => {
      state.userinfo.children = list;
      setUserStorage(state.userinfo);
    },
    setDeptList: (state, list) => {
      state.deptList = list;
    },
    SET_NEEDUPDATE: (state, info) => {
      state.needUpdate[info.key] = info.value;
    },
  },
  actions: {
    async getDeptList({ commit }) {
      let result = await deptList();
      let list = result.data || [];
      list.unshift({
        deptId: "",
        name: "全部",
      });
      commit("setDeptList", list);
      return list;
    },
    async changeChild({ commit, state }, params) {
      let result = await childSwitch(params);
      let info = state.userinfo;
      info.studentId = params.studentId;
      commit("SET_USERINFO", info);
      return result;
    },
    async refreshToken({ commit, state }) {
      if (!state.token.accessToken || location.href.indexOf("login") >= 0)
        return false;
      try {
        let result = await login({
          accessToken: state.token.accessToken,
          loginType: "02",
          userType: state.userinfo.userType,
        });
        commit("SET_USERTYPE", result.data.userType);
        commit("SET_TOKEN", {
          accessToken: result.data.accessToken,
          refreshToken: result.data.refreshToken,
        });
        commit("SET_USERINFO", result.data);
        return result;
      } catch (err) {
        let type = state.userinfo.userType;
        let url = state.urlMap[type].login;
        store.commit("CLEAR_TOKEN");
        store.commit("SET_USERINFO", {});
        router.push(url + "?type=relogin");
      }
    },
    async login({ commit }, params) {
      params.loginType = "01";
      let result = await login(params);
      commit("SET_USERTYPE", result.data.userType);
      commit("SET_TOKEN", {
        accessToken: result.data.accessToken,
        refreshToken: result.data.refreshToken,
      });
      commit("SET_USERINFO", result.data);
      return result;
    },
    async getUserInfo({ commit }, params) {
      let result = await userInfo();
      commit("SET_USERINFO", result.data);
      return result;
    },
    async logout({ commit }) {
      let result = await logout();
      commit("CLEAR_TOKEN");
      commit("SET_USERINFO", {});
      return result;
    },
  },
});

export default store;
