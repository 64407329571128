import axios from "axios";
import router from "@/router";
import store from "@/store/index";

import { getToken, getUserType } from "@/common/tool.js";

const instance = axios.create({
  // baseURL: "http://kyxx-app.engr-z.com/",
  baseURL: process.env.VUE_APP_REQUEST_URL,
  // baseURL: "http://app.psky-school.com",
  timeout: 60000,
});

//添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (token.refreshToken) {
      config.headers["refreshToken"] = token.refreshToken;
    }
    return config;
  },
  (error) => {
    //对请求错误做什么
    return Promise.reject(error);
  }
);

//http reponse响应拦截器
instance.interceptors.response.use(
  (response) => {
    // console.log("fREPONSE",response);
    // console.log('返回的新的REPONSE.token',response.headers.token);
    // if(!response.data.success){
    //   return Promise.reject(response.data);
    // }
    if (
      !response.data.success &&
      response.data.msg.indexOf("refreshToken") >= 0
    ) {
      let type = getUserType();
      let url = store.state.urlMap[type].login;
      store.commit("CLEAR_TOKEN");
      store.commit("SET_USERINFO", {});
      router.push(url + "?type=relogin");
      response.data.msg = "登录已过期，请重新登录";
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => {
    return error.response.data;
  }
);

export const get = (url, params = {}) => {
  return instance
    .get(
      url,
      { params },
      {
        headers: {
          // 'Content-Type' : 'application/josn'
        },
      }
    )
    .then((response) => {
      if (!response.success) {
        return Promise.reject(response);
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const post = (url, data = {}) => {
  return instance
    .post(url, data, {
      headers: {
        // 'Content-Type' : 'application/josn'
      },
    })
    .then((response) => {
      if (!response.success) {
        return Promise.reject(response);
      }
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
