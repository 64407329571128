<template>
  <!-- <keep-alive>
    <router-view />
  </keep-alive> -->
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="router-view">
      </router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      class="router-view"
    ></router-view>
  </div>
</template>
<script>
import { getUserType, throttle, isScrollToBottom } from "@/common/tool";
export default {
  beforeCreate() {
    this.$store.dispatch("refreshToken");
    let type = getUserType();
    this.$store.commit("SET_USERTYPE", type);
    window.isScrollToBottom = isScrollToBottom;
    window.onPageScroll = function (e) {
      window.pageScroll(e);
    };
    window.pageScroll = function () {};
    window.addEventListener("scroll", throttle(window.onPageScroll, 400));
  },
};
</script>
<style lang="less">
@import "@/assets/css/var.less";
/*每个页面公共css */
* {
  box-sizing: border-box;
}
html {
  font-size: 13.33vw;
}
body {
  padding: 0;
  margin: 0;
  background: #f8f8f8;
}
.flex {
  display: flex;
}
.f-wrap {
  flex-wrap: wrap;
}
.f-col {
  flex-direction: column;
}
.al-center {
  align-items: center;
}
.jc-sb {
  justify-content: space-between;
}
.jc-sa {
  justify-content: space-around;
}
.jc-center {
  justify-content: center;
}
table {
  border-collapse: collapse;
  font-size: 0.28rem;
}
table,
th,
td {
  border: 1px solid black;
}
th,
td {
  padding: 0.1rem;
}
</style>
